import gsap from 'gsap';


window.addEventListener('load', () => {
    if(window.isMobile) return;
    var cursor = document.querySelector(".cursor"),
        follower = document.querySelector(".cursor-follower");

    var posX = 0,
    posY = 0,
    mouseX = 0,
    mouseY = 0;

    gsap.to({}, 0.016, {
        repeat: -1,
        onRepeat: function() {
            // console.log(posX);
            let windowScrollTop = window._scrollTop()
            posX = mouseX / window.innerWidth * 100
            posY = mouseY / window.innerHeight * 100


            gsap.set(follower, {
                css: {
                    left: `calc(${posX}vw - 20px)`,
                    top: `calc(${posY}vh - 20px - ${windowScrollTop}px)`
                }
            });
        }
    }); 

    window.addEventListener('scroll', (e) => {
        gsap.set(follower, {opacity: 0,ease: 'none', })
    })

    window.addEventListener('mousemove', (e) => {
        // gsap.set(follower, {opacity: 1})
        gsap.set(follower, {ease: 'none', opacity: 1,})
    })

    window.addEventListener('mousemove', (e) => {
        mouseX = e.pageX;
        mouseY = e.pageY;
    })

    let registerMouseEvent = (item) => {
        // console.log(item);
        item.addEventListener('mouseenter', () => {
            cursor.classList.add('active')
            follower.classList.add('active')
            
        })
        item.addEventListener('mouseleave', () => {
            cursor.classList.remove('active')
            follower.classList.remove('active')
        })
    }
    
    document.querySelectorAll('.nav__item').forEach(item => {
        registerMouseEvent(item)
    })

    document.querySelectorAll('.letters > *').forEach(item => {
        registerMouseEvent(item)
    })

    registerMouseEvent(document.querySelector('.emailview'))
    
})