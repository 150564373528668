import {preloadImages, preloadFonts, clamp, map} from './utils';

import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger)

let viewportSize = window.innerWidth
window.isMobile = viewportSize < 768 ? true : false

import './scroller';
import './mount';
import './timelines';
import './_clients.tl';
import './_methods.tl';
import './_about.tl';
import './_contacts.tl';
import './cursor';
// import './_projectItem.tl';

window.addEventListener('load', () => {
  document.querySelectorAll('.header .nav__item').forEach(item => {
    item.addEventListener('click', () => {

      document.querySelectorAll('.header .nav__item').forEach(item => {item.classList.remove('active')})
      
      let attr = item.getAttribute('view')
      window.activePage = attr

      if(attr === 'works') {
        gsap.to('.letters > *', {color: "#fff"})
        gsap.to('.intro-2', {color: "#fff"})
        gsap.to('.nav__item', {color: '#fff'})
        window.scrollTo(0, 0)
        return;
      }

      if(attr === 'services' || attr === 'contact') {
        gsap.to('.letters > *', {color: "#fff"})
        gsap.to('.intro-2', {color: "#fff"})
        gsap.to('.nav__item', {color: '#fff'})
      }
      else {
        setTimeout(() => {
          gsap.to('.letters > *', {color: "#000"})
          gsap.to('.intro-2', {color: "#000"})
          gsap.to('.nav__item', {color: '#000'})
        }, 100)
      }

      let top = document.querySelector(`.${attr}`).offsetTop

      if(item.classList.contains('active')) return;
      item.classList.add('active')
      
      if(attr === 'methodsPage') {
        top = top + window.innerHeight
      }
      else if(attr === 'services') {
        top = top - window.innerHeight*.25
      }
      else {
        top = top - window.innerHeight*.1
      }
      window.scrollTo(0, top)
    })
  })

  const clientsTitleLines = document.querySelectorAll('.welcomeinfo div');

  clientsTitleLines.forEach(function(item, index){
      let words = item.textContent.split(' ');

      words = words.map(word => {
          let letters = word.split('');
          letters = letters.map(letter => `<span class="word_inner">${letter}</span>`);
          return letters.join('');
      });
      words = words.map(word => `<div class="word">${word}</div>`);
      item.innerHTML = words.join(' ');

      // console.log(item);
      
  })
  
  // Preload images and fonts
  Promise.all([preloadImages('.project__item-imginner')]).then(() => {
    // Remove loader (loading class)
    // setTimeout(function() {
      gsap.to('.loader', {y: '-100%', opacity: 0, duration: 1})
      document.body.classList.remove('loading');

      // gsap.set('.letters div', { clearProps: true });
      
      window.rectTL.play()

      setTimeout(function(){
        document.querySelector('.loader').remove()
      }, 1000)
      
   
      setTimeout(function(){
        if(window.isMobile) {
          gsap.set('.word', {opacity: 1})
        }
        else {
          clientsTitleLines.forEach((item, index) => {
            let itemWords = gsap.utils.toArray(item.querySelectorAll('.word'))
  
            let delay = 0
            let stagger = 0.30
            if(index === 1) {
              delay = 3
            }
  
            gsap.to(itemWords, {
              opacity: 1,
              duration: 1,
              stagger: stagger,
              delay
            });
          })
        }
      }, 1400)
      
      window.welcomeTl.play()

      

      document.querySelectorAll('.letters div').forEach(item => {
        item.addEventListener('click', ()  => {
          document.querySelectorAll('.header .nav__item').forEach(item => {item.classList.remove('active')})
          window.scrollTo(0,0);
        })
      })
      
    // }, 1500)
  });
});