import gsap from "gsap";

var rootVars = document.querySelector(':root');

let methodsTextsToggler = (show) => {
    let methodsTitleDivs = document.querySelectorAll('.methods__title div')
    if(show) {
        methodsTitleDivs.forEach(item => item.classList.add('shown'))
    }
    else {
        methodsTitleDivs.forEach(item => item.classList.remove('shown'))
    }
}

window.addEventListener('load', () => {
    document.querySelectorAll('.methodsNav__item').forEach((nav, index) => {
        nav.addEventListener('click', () => {
            
            setTimeout(() => {
                document.querySelectorAll('.methodsNav__item').forEach(item => item.classList.remove('active'))
                nav.classList.add('active')
            }, 50)
            
            let scrollElement = document.querySelector('.methodsNavPage .section__cards');
            let viewElement = document.querySelector('.methodsSection')

            let progress = 0
            if(index === 1) {
                progress =  25 / 100
            }
            if(index === 2) {
                progress =  25 * 2 / 100
            }
            if(index === 3) {
                progress =  25 * 3 / 100
            }

            // console.log(document.querySelector('.methodsNavPage').offset);
            let top = document.querySelector('.methodsPage').offsetTop + window.innerHeight + viewElement.offsetWidth * index
                
            window.scrollTo(0,top);
        })
    })
    
      let methodsPage = document.querySelector('.methodsPage')
      let pinElement = methodsPage.querySelector('.section__card')

      gsap.to(methodsPage, {
          scrollTrigger: {
            trigger: methodsPage,
            pin: pinElement,
            start: 'top top+=' + window.innerHeight * .15,
            end: 'bottom center',
            onLeave: () => {
                rootVars.style.setProperty('--color-bg', '#D9D9D9')
                gsap.to('.methods__title', {opacity: 0})
                methodsTextsToggler(false)
                gsap.to(document.querySelector('.intro-2'), {opacity: 1})
            },
            onLeaveBack: () => {
                rootVars.style.setProperty('--color-bg', '#D9D9D9')
                gsap.to('.methods__title', {opacity: 0})
                methodsTextsToggler(false)

                
                gsap.to('.letters > *', {color: "#000"})
                gsap.to('.intro-2', {color: "#000"})
                gsap.to('.nav__item', {color: '#000'})
                
                gsap.to(document.querySelector('.intro-2'), {opacity: 1})
            },
            onEnter: () => {
                rootVars.style.setProperty('--color-bg', '#000')
                gsap.set('.methods__title', {opacity: 1})
                methodsTextsToggler(true)

                gsap.to(document.querySelector('.intro-2'), {opacity: 0})

                gsap.to('.letters > *', {color: "#fff"})
                gsap.to('.intro-2', {color: "#fff"})
                gsap.to('.nav__item', {color: '#fff'})
            },
            onEnterBack: () => {
                rootVars.style.setProperty('--color-bg', '#000')
                gsap.set('.methods__title', {opacity: 1})
                methodsTextsToggler(true)
                
                gsap.to(document.querySelector('.intro-2'), {opacity: 0})

                gsap.to('.letters > *', {color: "#fff"})
                gsap.to('.intro-2', {color: "#fff"})
                gsap.to('.nav__item', {color: '#fff'})
            },
          },
      })
})
