
import {gsap} from 'gsap';

window.addEventListener('load', () => {
    window.rectTL = gsap.timeline({paused: true}).to(
        'rect', {
            attr:{x:window.innerWidth},
            duration: 1, 
            onComplete: () => {
                if(document.querySelector('rect')) {
                    document.querySelector('rect').remove()
                }
            }
        })
    
    window.welcomeTl = gsap.timeline({paused: true})
        .from('.intro', {opacity: 0, x: -100}) // intro text
        .from('.header .logo', {opacity: 0, y : '100%'}, '-=0.5')
        .fromTo("header .nav__item", {
            opacity: 0,
            y: 25,
            paused: true
        }, {
            opacity: 1,
            y: 0,
            ease: "ease",
            stagger: ".1"
        }, '-=.5') // header navigation
        // .from('figure', {stagger: .1, opacity: 0, top: window.innerHeight}, '<') // project items
        .from('footer', {opacity: 0, y : 40}) // footer
        .from('.project__item', {opacity: 0})

})