import gsap from "gsap";

var rootVars = document.querySelector(':root');

window.addEventListener('load', () => {
    // Clients split text
    let clientsTl = gsap.timeline({
        scrollTrigger: {
            trigger: '.clientsPage',
            scrub: 1,
            start: 'top 80%',
            end: 'top 30%',
        },
    })

    const clientsTitleLines = document.querySelectorAll('.clients__title div');

    clientsTitleLines.forEach(function(item, index){
        let words = item.textContent.split(' ');

        words = words.map(word => {
            let letters = word.split('');
            letters = letters.map(letter => `<span class="word_inner">${letter}</span>`);
            return letters.join('');
        });
        words = words.map(word => `<div class="word">${word}</div>`);
        item.innerHTML = words.join(' ');

        // console.log(item);
        let itemWords = gsap.utils.toArray(item.querySelectorAll('.word'))

        clientsTl.from(itemWords, {
            opacity: 0,
            y: 100,
            stagger: .1,
            ease: 'none',
        })
    })

    let clients = gsap.utils.toArray('.clients__list-item')
    gsap.from(clients, {
        scrollTrigger: {
            trigger: clients,
            scrub: 1,
            start: 'top 80%',
            end: 'top center',
        },
        opacity: 0,
        y: 100,
        stagger: {
            from: 'random',
            amount: 1.5,
            axis: 'x'
        },
        ease: 'none',
    })
})