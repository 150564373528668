import gsap from 'gsap';

var rootVars = document.querySelector(':root');

window.addEventListener('load', () => {
    const sectionsTl = gsap.timeline({
        scrollTrigger: {
            trigger: '.services',
            // markers: true,
            scrub: 1,
            start: 'top 80%',
            end: "top top-=" + window.innerHeight * .1,
            onEnter: () => {
                rootVars.style.setProperty('--color-bg', '#000')
                // rootVars.style.setProperty('--color-follower', '#000')
                rootVars.style.setProperty('--color-cursor', 'rgba(255,255,255,.5)')
            },
           
            
            onEnterBack: () => {
                rootVars.style.setProperty('--color-bg', '#000')
                // rootVars.style.setProperty('--color-follower', '#000')
                rootVars.style.setProperty('--color-cursor', 'rgba(255,255,255,.5)')
            }
        }
    })
    sectionsTl.from('.services__title', {
        opacity: 0,
        y: 50,
        ease: 'easeIn'
    })

    sectionsTl.from('.servicesItem', {
        opacity: 0,
        y: 100,
        stagger: {
            from: 'start',
            amount: 0.5,
            axis: 'x'
        },
        ease: 'none',
    })
})