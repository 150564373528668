import gsap from "gsap";

let els = {
    tailContainer : document.querySelector('.mountain-container'),
    tail: document.querySelector('.mountain-path'),
    horizontalSection: document.querySelector('.projectsPage'),
}

const APP = {};
let containerSize = els.horizontalSection

const config = {
    width : 0,
    height: 0,
    color: "#939393",
    pathSize: 1,
    points: false,
    grid: {
        spacing: 100,
        active: false,
    }
}
// 768px
window.addEventListener('load', () => {
    
    containerSize = els.horizontalSection

    els.tailContainer.style.width = containerSize.clientWidth

    config.width = containerSize.clientWidth
    config.height = containerSize.clientHeight

    APP.svgelement = els.tail
    APP.svgelement.style.width = config.width
    APP.svgelement.style.height = config.height

    document.querySelector('.mountain-path').style.width = document.querySelector('.projectsPage').scrollWidth + 'px'

    if(config.grid.active) {
        gridLines();
    }
    Points();
})

let fillRect = () => {
    const rect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
        rect.setAttributeNS(null, "width", window.innerWidth);
        rect.setAttributeNS(null, "height", window.innerHeight);

        rect.setAttributeNS(null, "fill", '#000');

        rect.classList.add('rect-shadow')

        APP.svgelement.appendChild(rect);
}

let gridLines = () => {
    const width = config.width;
    const height = window.innerHeight * 2;
    const spacing = config.grid.spacing;

    for(let x = 0; x <= width; x += spacing) {
        const line = document.createElementNS("http://www.w3.org/2000/svg", "line");

        line.setAttributeNS(null, "x1", x);
        line.setAttributeNS(null, "y1", 0);
        line.setAttributeNS(null, "x2", x);
        line.setAttributeNS(null, "y2", config.height);

        line.setAttributeNS(null, "stroke", "#D0D0D0");

        APP.svgelement.appendChild(line);
    }

    for(let y = 0; y <= height; y += spacing) {
        const line = document.createElementNS("http://www.w3.org/2000/svg", "line");

        line.setAttributeNS(null, "x1", 0);
        line.setAttributeNS(null, "y1", y);
        line.setAttributeNS(null, "x2", config.width);
        line.setAttributeNS(null, "y2", y);

        line.setAttributeNS(null, "stroke", "#D0D0D0");

        APP.svgelement.appendChild(line);
    }
}

function drawPoints(points, colour) {
    for(const point of points) {
        const circle = document.createElementNS("http://www.w3.org/2000/svg", "circle");
        circle.setAttribute('test', point[0])
        circle.setAttributeNS(null, "cx", point[0]);
        circle.setAttributeNS(null, "cy", point[1]);
        circle.setAttributeNS(null, "r", 10);

        circle.setAttributeNS(null, "fill", colour);

        APP.svgelement.appendChild(circle);
    }
}

function generatePath(points, relative) {
    let type = null;

    if(points.length === 3){
        type = "Q";
    }
    else if(points.length === 4){
        type = "C";
    }
    else if(points.length % 2 === 0){
        type = "C";
    }
    else{
        throw 'Number of points must be 3 or an even number more than 3';
    }

    const pathPoints = ["M ", points[0][0], ",", points[0][1], type];

    for(let p = 1, l = points.length; p < l; p++) {
        if(p >= 4 && p % 2 === 0)
            pathPoints.push("S");

        pathPoints.push(points[p][0]);
        pathPoints.push(",");
        pathPoints.push(points[p][1]);
    }

    return pathPoints.join(" ");
}

function drawBezier(pathString, stroke, isMain) {
    const path = document.createElementNS("http://www.w3.org/2000/svg", "path");

    if(isMain) {
        path.classList.add('animate-path')
    }
    
    path.setAttributeNS(null, "d", pathString);
    path.setAttributeNS(null, "stroke", stroke);
    path.setAttributeNS(null, "fill", "transparent");
    path.setAttributeNS(null, "stroke-width", `${config.pathSize}px`);

    els.tail.appendChild(path);
}

function Points() {
    const wh = window.innerHeight; // window height
    let ww = window.innerWidth; // window width
    if(window.isMobile) {
        ww = ww * .8
    }
    
    let points = [
        [0, wh*0.8],
    
        [(200 / 1400) * ww, 700],
        [(500 / 1400) * ww, 450],
        [(800 / 1400) * ww, 550],
    
        [(1100 / 1400) * ww, 300],
        [(1400 / 1400) * ww, 300],
        [(1700 / 1400) * ww, 600],
        [(2000 / 1400) * ww, 400],
    
        [(2300 / 1400) * ww, 300],
        [(2600 / 1400) * ww, 120],
        [(2900 / 1400) * ww, 300],
        [(3200 / 1400) * ww, 400],
    
        [(3500 / 1400) * ww, 300],
        [(3800 / 1400) * ww, 150],
        [(4100 / 1400) * ww, 300],
        [(4400 / 1400) * ww, 400],
    
        [(4700 / 1400) * ww, 300],
        [(5000 / 1400) * ww, 100],
        [(5300 / 1400) * ww, 300],
        [(5700 / 1400) * ww, 400],
    
        [(6000 / 1400) * ww, 300],
        [(6300 / 1400) * ww, 150],
        [(6600 / 1400) * ww, 300],
        [(6900 / 1400) * ww, 400],
    
        [(7200 / 1400) * ww, 300],
        [(7500 / 1400) * ww, 100],
        [(7800 / 1400) * ww, 300],
        [(8100 / 1400) * ww, 400],
    
        [(8400 / 1400) * ww, 300],
        [(8700 / 1400) * ww, 200],
        [(9000 / 1400) * ww, 300],
        [(9300 / 1400) * ww, 450]
    ];

    if(config.points) {
        drawPoints(points, config.color);
    }

    const pathString = generatePath(points, false);

    drawBezier(pathString, config.color, false);
    
    drawBezier(pathString, '#fff', true);

    fillRect()

    // window.addEventListener('resize', () => {
    //     const pathString = generatePath(points, false);

    //     drawBezier(pathString, config.color, false);
        
    //     drawBezier(pathString, '#fff', true);

    //     fillRect()
    // })

    if(!window.isMobile) {
        let path = els.tail.querySelector('.animate-path')

        let pathLength = path.getTotalLength();
        path.style.strokeDasharray = pathLength + ' ' + pathLength;
        path.style.strokeDashoffset = pathLength;

        
        window.addEventListener('scroll', (e) => {
            let projectsCurrentPosition = parseInt(document.querySelector('.projects').getAttribute('style').split("(")[1].split(" ")[0])
            
            let c = document.querySelector('.projects')

            let maxScrollTop = c.clientWidth - window.innerWidth,
                percentDone = projectsCurrentPosition / (maxScrollTop-9000),
                length = percentDone * pathLength;
            
            path.style.strokeDashoffset = pathLength - length
        })
    }
}