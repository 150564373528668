import gsap from 'gsap';

var rootVars = document.querySelector(':root');

window.addEventListener('load', () => {
    // if(window.isMobile) return;
    gsap.timeline({
        scrollTrigger: {
            trigger: '.contact',
            // markers: true,
            start: 'top bottom',
            end: 'top top',
            toggleActions: "play none reverse none",
            onEnter: () => {
                rootVars.style.setProperty('--color-bg', '#000')
                gsap.set('.nav__privacy', {display: 'block'})
                gsap.set('.icon-mouse', {display: 'none'})
                console.log('ENTERED');
            },
            onEnterBack: () => {
                rootVars.style.setProperty('--color-bg', '#000')
                gsap.set('.nav__privacy', {display: 'block'})
                if(window.isMobile) {
                    gsap.set('.icon-mouse', {display: 'none'})
                }
                else {
                    gsap.set('.icon-mouse', {display: 'none'})
                }
            },
            onLeave: () => {
                console.log('LEAVED');
                gsap.set('.nav__privacy', {display: 'none'})
                if(window.isMobile) {
                    gsap.set('.icon-mouse', {display: 'none'})
                }
                else {
                    gsap.set('.icon-mouse', {display: 'block'})
                }
            },
            onLeaveBack: () => {
                gsap.set('.nav__privacy', {display: 'none'})
                if(window.isMobile) {
                    gsap.set('.icon-mouse', {display: 'none'})
                }
                else {
                    gsap.set('.icon-mouse', {display: 'block'})
                }
            }
        }
    })
    .from('.contact', {
        opacity: 0,
        y: 100
    })
})