import gsap from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {preloadImages, preloadFonts, clamp, map} from './utils';

var rootVars = document.querySelector(':root');

window.addEventListener('load', () => {
  // Section Pinning
  gsap.utils.toArray(".section").forEach((section) => {
    // Check if section has horizontal scrolling
    if (section.dataset.type === "horizontal") {
      // if(window.isMobile && section.classList.contains('projectsPage')) {
      //     section.querySelectorAll('.section__card')[0].remove()
      //     section.querySelectorAll('.section__card')[1].remove()
      // }
      const cards = section.querySelector(".section__cards");
      const card = section.querySelector(".section__card");

      let pageName = section.classList.contains('projectsPage') ? 'projectScroll' : 'methodsScroll'
      
      window[pageName] = gsap.to(cards, {
        x: () => {
          return -cards.scrollWidth + card.offsetWidth + card.offsetLeft;
        },
        ease: "none",
        scrollTrigger : {
          trigger: section,
          start: () => "center center",
          end: () => `+=${cards.scrollWidth - card.offsetWidth}`,
          scrub: true,
          pin: true,
          onUpdate: (item) => {
            if(pageName === 'methodsScroll') {
              let progress = item.progress * 100
              // console.log(progress);
              document.querySelectorAll('.methodsNav__item').forEach(item => item.classList.remove('active'))
              if(progress <= 25) {
                document.querySelectorAll('.methodsNav__item')[0].classList.add('active')
              }

              if(progress > 25 && progress <= 50) {
                document.querySelectorAll('.methodsNav__item')[1].classList.add('active')
              }

              if(progress > 50 && progress <= 75) {
                document.querySelectorAll('.methodsNav__item')[2].classList.add('active')
              }

              if(progress > 75) {
                document.querySelectorAll('.methodsNav__item')[3].classList.add('active')
              }

            }
          },
          onLeave: (item) => {
              if(item.vars.trigger && item.vars.trigger.classList.contains('projectsPage')) {
                rootVars.style.setProperty('--color-bg', '#D9D9D9')
  
                gsap.to('.intro', {opacity: 0})
                gsap.to('.intro-2', {opacity: 1})
                gsap.to('.mountain-container', {opacity: 0})


                gsap.to('.letters > *', {color: "#000"})
                gsap.to('.intro-2', {color: "#000"})
                gsap.to('.nav__item', {color: '#000'})
              }
  
              if(item.vars.trigger && item.vars.trigger.classList.contains('methodsPage')) {
                rootVars.style.setProperty('--color-bg', '#000')

                gsap.to('.letters > *', {color: "#000"})
                gsap.to('.intro-2', {color: "#000"})
                gsap.to('.nav__item', {color: '#000'})
              }
  
              if(item.vars.trigger && item.vars.trigger.classList.contains('methodsNavPage')) {
                rootVars.style.setProperty('--color-bg', '#000')

                gsap.to('.letters > *', {color: "#fff"})
                gsap.to('.intro-2', {color: "#fff"})
                gsap.to('.nav__item', {color: '#fff'})
              }
          },
          onEnter: (item) => {
            if(item.vars.trigger && item.vars.trigger.classList.contains('projectsPage')) {
              rootVars.style.setProperty('--color-bg', '#000')
  
              gsap.to('.intro', {opacity: 1})
              gsap.to('.intro-2', {opacity: 0})
              gsap.to('.mountain-container', {opacity: 1})

              gsap.to('.letters > *', {color: "#fff"})
              gsap.to('.intro-2', {color: "#fff"})
              gsap.to('.nav__item', {color: '#fff'})
            }
  
            if(item.vars.trigger && item.vars.trigger.classList.contains('methodsPage')) {
              rootVars.style.setProperty('--color-bg', '#D9D9D9')

              gsap.to('.letters > *', {color: "#fff"})
              gsap.to('.intro-2', {color: "#fff"})
              gsap.to('.nav__item', {color: '#fff'})
            }
  
            if(item.vars.trigger && item.vars.trigger.classList.contains('methodsNavPage')) {
              rootVars.style.setProperty('--color-bg', '#D9D9D9')

              gsap.to('.letters > *', {color: "#000"})
              gsap.to('.intro-2', {color: "#000"})
              gsap.to('.nav__item', {color: '#000'})

            }
          },
          onEnterBack: (item) => {
            if(item.vars.trigger && item.vars.trigger.classList.contains('projectsPage')) {
              rootVars.style.setProperty('--color-bg', '#000')
  
              gsap.to('.intro', {opacity: 1})
              gsap.to('.intro-2', {opacity: 0})
              gsap.to('.mountain-container', {opacity: 1})

              gsap.to('.letters > *', {color: "#fff"})
              gsap.to('.intro-2', {color: "#fff"})
              gsap.to('.nav__item', {color: '#fff'})

            }
  
            if(item.vars.trigger && item.vars.trigger.classList.contains('methodsPage')) {
              rootVars.style.setProperty('--color-bg', '#D9D9D9')

              gsap.to('.letters > *', {color: "#fff"})
              gsap.to('.intro-2', {color: "#fff"})
              gsap.to('.nav__item', {color: '#fff'})
            }
  
            if(item.vars.trigger && item.vars.trigger.classList.contains('methodsNavPage')) {
              rootVars.style.setProperty('--color-bg', '#D9D9D9')

              gsap.to('.letters > *', {color: "#000"})
              gsap.to('.intro-2', {color: "#000"})
              gsap.to('.nav__item', {color: '#000'})
            }
          }
        },
      });

      // let navs = document.querySelectorAll('.methodsNavPage .section__card')
      // let maxWidth = 0;

      // const getMaxWidth = () => {
      //   maxWidth = 0;
      //   navs.forEach((section) => {
      //     maxWidth += section.offsetWidth;
      //   });
      // };
      // getMaxWidth();
      // ScrollTrigger.addEventListener("refreshInit", getMaxWidth);

      // navs.forEach((sct, i) => {
      //   ScrollTrigger.create({
      //     trigger: sct,
      //     start: () => 'top top-=' + (sct.offsetLeft - window.innerWidth/2) * (maxWidth / (maxWidth - window.innerWidth)),
      //     end: () => '+=' + sct.offsetWidth * (maxWidth / (maxWidth - window.innerWidth)),
      //     toggleClass: {targets: sct, className: "active"}
      //   });
      // });
      
    }
  });

  let section = document.querySelector('.projectsPage')

  let projecItems = document.querySelectorAll('.project__item')

  let width = 0
  projecItems.forEach(project => {
    width += project.offsetWidth
  })

  !window.isMobile &&
  gsap.to('.mountain-path', {
    x: `${-(section.scrollWidth - document.documentElement.clientWidth)}px`,
    ease: 'none',
    
    scrollTrigger: {
      trigger: '.mountain-container',
      start: 'top top',
      // markers: true,
      end: () => `+=${section.scrollWidth}`,
      scrub: true,
      onUpdate: () => {
        console.log('upda');
      }
    }
  })
  
  let animateProjects = () => {
    // if(window.isMobile) return;
    let options = {
      
    }
    // if(window.isMobile) return
    let block = gsap.utils.toArray('.project__item')

    let maxWidth = 0;
    const getMaxWidth = () => {
      maxWidth = 0;
      block.forEach((section) => {
        let style = section.currentStyle || window.getComputedStyle(section);

        maxWidth += (section.offsetWidth + parseInt(style.marginRight));
      });
    };
    getMaxWidth();

    gsap.set('.projectsPage', {width:maxWidth})

    function getRandomInt(max) {
      return Math.floor(Math.random() * max);
    }

    block.forEach(function(item, index) {
      let projectIndex = item.getAttribute('data-scroll-speed')
      
      gsap.set(item, {y: projectIndex * (window.innerHeight * .15)})
      
      if(window.isMobile) {
        gsap.set(item, {y: projectIndex * (window.innerHeight * .08)}) 
      }
      
      gsap.timeline({
        scrollTrigger: {
          trigger: item,
          invalidateOnRefresh: true,
          start: () => 'top top-=' + (item.offsetLeft - window.innerWidth - (item.offsetWidth)),
          end: () => '+=' + (item.offsetWidth * (maxWidth / (maxWidth - window.innerWidth)) + window.innerWidth + item.offsetWidth),
          scrub:true,
          toggleActions: "play none reverse none",
        }
      })
      .to(item, {y: 0})
      // .to(item.querySelector('.project__item-imginner'), {
      //   yPercent: window.isMobile ? 0 : -40
      // }, '<')
    })
  }
  
  animateProjects()
})
